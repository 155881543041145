.success-container {
  color: beige;
  text-align: center;
  background-color: #1c1c1c;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 1em 0;
  display: none;
  overflow: hidden;
}

.streak-symbols, .streak-legend {
  color: beige;
  word-wrap: break-word;
  background: #00c3dc99;
  border-radius: 5px;
  max-width: 100%;
  padding: .3em .6em;
  box-shadow: 0 2px 4px #0000004d;
}

.streak-symbols {
  margin-bottom: 1em;
  font-size: 1rem;
}

.success-message {
  color: beige;
  box-sizing: border-box;
  max-width: 90%;
  margin: 1em auto;
  font-size: 1.25rem;
  line-height: 1.5;
}

#share-button {
  color: beige;
  cursor: pointer;
  z-index: 10;
  background: #ff6f61;
  border: none;
  border-radius: 5px;
  align-self: center;
  margin: 1em auto;
  padding: 10px 20px;
  font-size: 1rem;
}

#share-button:hover {
  background: #e05850;
}

.streak-legend {
  align-self: center;
  margin-top: 1em;
  font-size: .7rem;
}

@media (width >= 481px) {
  .streak-symbols {
    font-size: 1.5rem;
  }

  .success-message {
    font-size: 1.75rem;
  }

  #share-button {
    padding: 12px 24px;
    font-size: 1.25rem;
  }

  .streak-legend {
    font-size: .9rem;
  }
}

@media (width >= 769px) {
  .streak-symbols {
    font-size: 1.75rem;
  }

  .success-message {
    font-size: 2rem;
  }

  #share-button {
    padding: 14px 28px;
    font-size: 1.5rem;
  }

  .streak-legend {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index.1b41a075.css.map */
