/* Base styles for mobile devices (small screens) */
.success-container {
    display: none; /* Hidden by default */
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 1em 0;
    background-color: #1C1C1C;
    color: #F5F5DC;
    text-align: center;
    overflow: hidden;
}

.streak-symbols, 
.streak-legend {
    color: #F5F5DC;
    background: rgba(0, 195, 220, 0.6);
    padding: 0.3em 0.6em;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    word-wrap: break-word; /* Enable wrapping on smaller screens */
}

.streak-symbols {
    font-size: 1rem;
    margin-bottom: 1em;
}

.success-message {
    font-size: 1.25rem;
    color: #F5F5DC;
    max-width: 90%;
    margin: 1em auto;
    line-height: 1.5;
    box-sizing: border-box;
}

#share-button {
    background: #FF6F61;
    color: #F5F5DC;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin: 1em auto;
    align-self: center;
    z-index: 10;
}

#share-button:hover {
    background: #E05850;
}

.streak-legend {
    font-size: 0.7rem;
    margin-top: 1em;
    align-self: center;
}

/* Medium screens (min-width: 481px) */
@media (min-width: 481px) {
    .streak-symbols {
        font-size: 1.5rem;
    }

    .success-message {
        font-size: 1.75rem;
    }

    #share-button {
        font-size: 1.25rem;
        padding: 12px 24px;
    }

    .streak-legend {
        font-size: 0.9rem;
    }
}

/* Large screens (min-width: 769px) */
@media (min-width: 769px) {
    .streak-symbols {
        font-size: 1.75rem;
    }

    .success-message {
        font-size: 2rem;
    }

    #share-button {
        font-size: 1.5rem;
        padding: 14px 28px;
    }

    .streak-legend {
        font-size: 1rem;
    }
}